"use client";

import { MessageBox, ModalHandle, Text } from "@hkexpressairwayslimited/ui";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCallback, useEffect, useState } from "react";
import { APPLICATION_ERROR } from "utils/errors";

export default function ComponentError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const [appErrorModalHandle, setAppErrorModalHandle] = useState<ModalHandle | null>();
  const { t } = useTransContent();

  useEffect(() => {
    if (appErrorModalHandle && !appErrorModalHandle.showModal) {
      appErrorModalHandle?.open();
    }
  }, [appErrorModalHandle]);

  const handleCloseApiErrorModal = useCallback(() => {
    appErrorModalHandle?.close();
    reset();
  }, [reset, appErrorModalHandle]);

  return (
    <MessageBox
      id='appError'
      ref={setAppErrorModalHandle}
      title={t("web.general.appError.title")}
      ctaText={t("web.general.appError.ctaText")}
      onClickCta={handleCloseApiErrorModal}
      onClose={handleCloseApiErrorModal}
    >
      <Text>{t("web.general.appError.content", { errorCodes: APPLICATION_ERROR })}</Text>
    </MessageBox>
  );
}
